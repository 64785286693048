import axios from "axios";

declare const PRODUCTION: any;

interface IAuthService {
  scheme: string,
  clientId: string,
  scopes: string,
  endpoint: string,
  refreshTokenInterval: number
}
interface ISpidService {
  scheme: string,
  clientId: string,
  clientSecret: string,
  scopes: string,
  endpoint: string,
  refreshTokenInterval: number
}


interface IEsriService {
  endpoint: string
}

interface ISettings {
  baseUrl: string,
  authService: IAuthService,
  spidConfiguration: ISpidService,
  protocollazioneSid: string,
  corpiIdriciLayerFiumi: string
  corpiIdriciLayerSpecchiAcqua: string,
  pagoPa: {
    tooltipContent: string,
    guideFileName: string
  }
}

// You can customize settings below :)
const settings: ISettings = {
  baseUrl: 'https://localhost:7019',
  authService: {
    scheme: 'idricoSpid',
    endpoint: 'https://localhost:55536',
    clientId: 'rgu_idrico',
    scopes: 'openid profile email offline_access',
    refreshTokenInterval: 5
  },
  spidConfiguration: {
    scheme: "spid",
    endpoint: "https://localhost:7019",
    clientId: "rgu_idrico_spid",
    clientSecret: "c15ec0c0-979a-4928-9fef-13e97a35a3fd",
    scopes: "openid profile email offline_access",
    refreshTokenInterval: 5
  },
  protocollazioneSid: 'https://localhost:7082',
  corpiIdriciLayerFiumi: "https://services-eu1.arcgis.com/djnMU8K5SsVj4H31/arcgis/rest/services/Fiumi_Umbria_2024/FeatureServer/0",
  corpiIdriciLayerSpecchiAcqua: "https://services-eu1.arcgis.com/djnMU8K5SsVj4H31/ArcGIS/rest/services/Specchi_d_acqua_Umbria_2024/FeatureServer/4",
  pagoPa: {
    tooltipContent: "Tramite il sito di pagoumbria accedere alla sezione 'Pagamenti Spontanei', selezionare dall'elenco 'Regione Umbria' e successivamente 'IDRICO'. Fornite i dati richiesti e seguite i passaggi a schermo. Per una guida completa cliccare il pulsante di aiuto",
    guideFileName: "pagoPaGuide.pdf"
  }
}


export async function loadSettings() {

  let response = null;

  try {
    response = await axios.get("/settings.custom.json");
    Object.assign(settings, response.data);
  } catch { }

  if (!response)
    try {
      response = await axios.get("/settings.json");
      Object.assign(settings, response.data);
    } catch { }

  try {
    if (PRODUCTION !== undefined && PRODUCTION) {
      console.error("NO CONFIGURATION FOUND !!", "please provide settings.json or settings.custom.json file !")
    }
  } catch { }

}



export default settings
