import Vue from 'vue';
import App from './App.vue';

import '@/router/permissions';
import { store, vueStore } from "@/store";
import router from './router';

import { authService } from '@/services/authService';

import i18n, { getLocale } from '@/lang';

import settings, { loadSettings } from '@/settings';

Vue.config.productionTip = false

import "@/assets/scss/index.scss";
import "bootstrap-italia";

import moment from 'moment';
moment.locale(getLocale());

// Register global mixin
Vue.mixin({
  methods: {
    $can(key: string) { return store.state.auth.claims.indexOf(key) >= 0; },
    $me() { return store.state.auth.userInfo; }
  }
});



// /* END ___  PROPEDEUTICITA' */
import Toggle from "@/components/__backOffice/toggleButton/toggle.vue";
import VTooltip from 'v-tooltip';
import microfrontends from "vue-mf-module";
Vue.use(microfrontends);
Vue.component('toggle', Toggle)
Vue.use(VTooltip)
// /* END ___ WT PROPEDEUTICITA' */


import NotificationCTOR from './components/Message/components/notification/notificationCTOR';
Vue.prototype.$notify = NotificationCTOR;

import MsgCTOR from './components/Message/components/msg/msgCTOR';
Vue.prototype.$msg = MsgCTOR;

import MessageBoxCTOR from './components/Message/components/modal/modalCTOR';
Vue.prototype.$modal = MessageBoxCTOR;
Vue.prototype.$confirm = MessageBoxCTOR.confirm;
Vue.prototype.$alert = MessageBoxCTOR.alert;

import * as filters from '@/filters';

Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string]: Function, })[key]);
});

import registerComponents from './libMain';
loadSettings().then(async () => {
  try {
    await authService.CheckLoginFromExternalProvider();
  } catch (err) {
    console.error('cannot upgrade authentication token.', err);
  }

  registerComponents();

  new Vue({
    router,
    store: vueStore,
    i18n,
    render: h => h(App)
  }).$mount('#app-rgu_idrico')
})


setInterval(async () => {
  await authService.AutoRenewToken();
}, settings.authService.refreshTokenInterval * 60 * 1000);

