import { domini } from "@/services/domini/@types/domini";
import { ActionTree, GetterTree } from "vuex";

export interface DominiStoreModel {
  comuni: domini.comuni[],
  province: string[],
}

export interface DominiStoreGetters {
  comuni: () => domini.comuni[]
  province: () => string[]
}

export interface DominiStoreActions {
  SetComuni: (comuni: domini.comuni[]) => void;
}

export const DominiStore = {
  PREFIX: "domini",
  namespaced: true,
  state: {
    comuni: [],
    province: [],
    colture: []
  },
  getters: {
    comuni: (state: DominiStoreModel) => (): domini.comuni[] => state.comuni,
    province: (state: DominiStoreModel) => (): string[] => state.province,
  } as GetterTree<DominiStoreModel, DominiStoreModel>,
  mutations: {
    SET_COMUNI(state: DominiStoreModel, _comuni: domini.comuni[]) {
      state.comuni = _comuni
    }
  },
  actions: {
    SetComuni({ commit }, _comuni) {
      commit("SET_COMUNI", _comuni)
    }
  } as ActionTree<DominiStoreActions, any>
}
