import { Vue, Component } from 'vue-property-decorator';
import MainHeaderAccounting from './main-header-accounting.vue';
import MainHeaderNavbar from './main-header-navbar.vue';

@Component({
  components: {
    MainHeaderAccounting,
    MainHeaderNavbar
  }
})
export default class MainHeader extends Vue { }