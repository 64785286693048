///PRELIEVI
export const TipiRichiesta = ['concessione', 'licenzaattingimento'];
export const TipiIrrigazione = ['pioggia', 'goccia'];
export const StatiRichiesta = ['bozza', 'preistruttoria', 'presentata', 'lavorazione', 'attesaintegrazione', 'archiviazione', 'rilascio'];
export const EsitiRichiesta = ['irricevibile', 'ricevibile', 'mancataescavazione'];
export const TipiValidita = ['nuova', 'rinnovo'];
export const TipiUso = [
  'domestico',
  'forzamotrice',
  'idroelettrico',
  'idropotabile',
  'industriale',
  'irrigazioneagricola',
  'nonspecificato',
  'autolavaggio',
  'geotermico',
  'irrigazioneareeverdi',
  'irrigazioneimpiantisportivi',
  'lavaggiomacchinari',
  'lavaggiostradepiazzaliecontrollopolveriinerti',
  'pescasportiva',
  'piscicolturaittiogenico',
  'antincendio',
  'trattamentiantiparassitari',
  'usovenatorio',
  'zootecnico',
  'beniservizi',
  'accumuloinvernaleinvaso',
  'irriguo',
  'abbeveraggioanimalicortile',
  'altro',
  'forzamotriceidroelettricograndiderivazioni',
  'forzamotriceidroelettricopiccolederivazioni',
  'abbattimentopolveri',
  'igienico'
];
export const TipiVariazioneRichiesta = [
  'ordinariaconcessione',
  'cambiotitolarita',
  'variazionesostanziale',
  'variazionenonsostanziale',
  'usodiverso',
  'irriguosopra',
  'irriguosotto',
  'sostituzionelicenza'
];
export const giorniSett = ['lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato', 'domenica'];
export const inQualitaDi = ['proprietarioterreno', 'legalerappresentante', 'altri', 'utilizzatore', 'titolaredittaindividuale', 'affittuario'];
export const tipiSoggetto = ['richiedente', 'compilatore'];
export const tipiFonteConcessione = ['fiume', 'torrente', 'fosso', 'canale', 'lago', 'stagnopalude', 'bacinoartificiale', 'sorgente'];
export const tipiFonte = ['fiume', 'torrente', 'fosso', 'canale', 'lago', 'invaso', 'sorgente', 'pozzo', 'diga', 'acquedotto', 'traversa', 'stagnopalude', 'torbiera', 'bacinoartificiale'];
export const tipiAcqua = ['sotterranea', 'superficiale', 'sorgente'];
export const tipiPunto = ['prelievo', 'restituzione'];
export const tipiRestituzione = ['dispersione', 'impiantofognario', 'corpoidrico', 'nonprevista', 'nondefinita', 'suolo'];
export const tipiCorpiIdrici = ['bacini' , 'corpiidricifluviali' , 'corpiidricisuperficiali' , 'corpiidricisotterranei']
export const tipiStrumento = ['pompa' , 'tubo' , 'trattorebotte' , 'condotta' , 'canale' , 'idrovora']
export const trasportabilitaStrumento = ['fisso' , 'semifisso' , 'mobile']
