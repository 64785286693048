import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import FrontOfficeLayout from '@/layout/frontOffice/frontOffice.vue';
import BackOfficeLayout from '@/layout/backOffice/backOffice.vue';

Vue.use(VueRouter);
export function constantRoutes(): RouteConfig[] {
  return [
    {
      path: '/anonymous',
      component: FrontOfficeLayout,
      redirect: '/login',
      meta: { hidden: true },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "login" */ '@/modules/Accounting/views/login.vue'),
          meta: {
            title: `Accedi`,
            hidden: true
          }
        },
        {
          path: '/login/credenziali',
          name: 'loginCredenziali',
          component: () => import(/* webpackChunkName: "login" */ '@/modules/Accounting/views/login-with-credential.vue'),
          meta: {
            title: `Accesso con Credenziali`,
            hidden: true
          }
        },
        {
          path: '/login/confermaEmail/:username/:code',
          name: 'confermaEmail',
          component: () => import(/* webpackChunkName: "login" */ '@/modules/User/backOffice/views/UserRegistrationView/UserConfirmEmail/confirmEmail.vue'),
          meta: {
            title: `Conferma E-Mail`,
            hidden: true
          },
          props: true
        },
        {
          path: '/login/resetPassword/:username/:pwToken',
          name: 'resetPassword',
          component: () => import(/* webpackChunkName: "login" */ '@/modules/User/backOffice/views/UserRegistrationView/UserResetPassword/resetPassword.vue'),
          meta: {
            title: `Imposta Password`,
            hidden: true
          },
          props: true
        }
      ]
    },
    {
      name: 'main',
      path: '/',
      component: FrontOfficeLayout,
      redirect: '/',
      children: [
        {
          component: () => import(/* webpackChunkName: "home" */ '@/views/frontOffice/home.vue'),
          path: '/',
          name: 'home'
        },
        {
          path: '/404',
          name: '404',
          component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue')
        },
        {
          path: '/401',
          name: '401',
          component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue')
        }
        //da spostare in permission rout
      ]
    }
  ];
}

export function permissionRoutes(): RouteConfig[] {
  return [
    {
      name: 'profilo',
      path: '/profilo',
      component: FrontOfficeLayout,
      redirect: '/profilo/le-tue-istanze',
      children: [
        {
          path: '/profilo/le-tue-istanze',
          name: 'profilo/le-tue-istanze',
          component: () => import(/* webpackChunkName: "profile" */ '@/modules/User/frontOffice/views/profilo.vue')
        }
      ]
    },
    {
      name: 'prelievi',
      path: '/prelievi',
      component: FrontOfficeLayout,
      children: [
        {
          path: '/prelievi/concessioni/add/:tipoCorpoIdrico?/:id?',
          name: 'addConcessione',
          component: () => import(/* webpackChunkName: "addPrelievo" */ '@/modules/Concessioni/add.vue'),
          meta: {
            title: `Aggiungi richiesta concessione`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/concessioni/list/:tipologia?',
          name: 'listConcessioni',
          component: () => import(/* webpackChunkName: "cambioTitolarita" */ '@/modules/Concessioni/views/listConcessioni.vue'),
          meta: {
            title: `Lista Procedure`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/concessioni/cambiotitolarita/:id?',
          name: 'cambioTitolarita',
          component: () => import(/* webpackChunkName: "cambioTitolarita" */ '@/modules/Concessioni/views/cambioTitolarita/cambioTitolarita.vue'),
          meta: {
            title: `Cambio Titolarita`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/concessioni/variazionesostanziale/:id?',
          name: 'variazionesostanziale',
          component: () => import(/* webpackChunkName: "cambioTitolarita" */ '@/modules/Concessioni/views/variazioneSostanziale/variazioneSostanziale.vue'),
          meta: {
            title: `Variazione Sostanziale`,
            hidden: false
          },
          props: true
        },
        ,
        {
          path: '/prelievi/concessioni/variazionenonsostanziale/:id?',
          name: 'variazionenonsostanziale',
          component: () => import(/* webpackChunkName: "cambioTitolarita" */ '@/modules/Concessioni/views/variazioneNonSostanziale/variazioneNonSostanziale.vue'),
          meta: {
            title: `Variazione non Sostanziale`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/attingimenti/add/:tipoVariazioneRichiesta/:id?',
          name: 'addAttingimenti',
          component: () => import(/* webpackChunkName: "addAttingimenti" */ '@/modules/Attingimenti/add.vue'),
          meta: {
            title: `Aggiungi richiesta attingimento`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/attingimenti/add/:tipoVariazioneRichiesta',
          name: 'addAttingimentiTipoVariazioneRichiesta',
          component: () => import(/* webpackChunkName: "addAttingimentiTipoVariazioneRichiesta" */ '@/modules/Attingimenti/add.vue'),
          meta: {
            title: `Aggiungi richiesta attingimento`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/detail/:id',
          name: 'detail',
          component: () => import(/* webpackChunkName: "detailAttingimento" */ '@/modules/Prelievi/frontOffice/detail/detail.vue'),
          meta: {
            title: `Prelievi`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/letture/list/:tipologia?',
          name: 'listaIstanzeLetture',
          component: () => import(/* webpackChunkName: "storicoLetture" */ '@/modules/Letture/listPrelievi.vue'),
          meta: {
            title: `Lista istanze`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/letture/list/punti-prelievo/:id',
          name: 'listaPuntiPrelievoLetture',
          component: () => import(/* webpackChunkName: "storicoLetture" */ '@/modules/Letture/components/listPuntiPrelievo/listPuntiPrelievo.vue'),
          meta: {
            title: `Lista Punti di prelievo`,
            hidden: false
          },
          props: true
        },
        {
          path: '/prelievi/attingimenti/detail/storico-letture/:idRichiesta/:id',
          name: 'storicoLetture',
          component: () => import(/* webpackChunkName: "storicoLetture" */ '@/modules/Letture/components/storico/storicoLetture.vue'),
          meta: {
            title: `Storico Letture`,
            hidden: false
          },
          props: true
        },
        ,
        {
          path: '/prelievi/attingimenti/clona',
          name: 'clonaAttingimento',
          component: () => import(/* webpackChunkName: "storicoLetture" */ '@/modules/Attingimenti/components/clona/listLicenze.vue'),
          meta: {
            title: `Clona attingimento`,
            hidden: false
          },
          props: true
        }
      ],
      meta: {
        claims: []
      }
    },
    {
      name: 'backoffice',
      path: '/back-office',
      component: BackOfficeLayout,
      redirect: '/back-office/dashboard',
      children: [
        {
          path: '/back-office/dashboard',
          name: 'backoffice-dashboard',
          component: () => import(/* webpackChunkName: "backoffice-dashboard" */ '@/views/backOffice/dashboard.vue'),
          meta: {
            claims: ['APP.canseebackoffice']
          }
        },
        {
          path: '/back-office/protocollazione',
          name: 'backoffice-protocollazione',
          component: () => import(/* webpackChunkName: "backoffice-protocollazione" */ '@/modules/Protocollazione/views/indexAttributes.vue'),
          meta: {
            claims: []
          }
        },
        {
          path: '/back-office/proceedings',
          name: 'backoffice-proceedings',
          component: () => import(/* webpackChunkName: "backoffice-proceedings" */ '@/modules/Prelievi/backOffice/views/Proceedings/Proceedings.vue'),
          meta: {
            claims: []
          }
        },
        {
          path: '/back-office/proceedings/detail/:id',
          name: 'backoffice-detail',
          component: () => import(/* webpackChunkName: "detailAttingimento" */ '@/modules/Prelievi/backOffice/views/detail/detail.vue'),
          meta: {
            claims: []
          },
          props: true
        },
        {
          path: '/back-office/vincolirichieste',
          name: 'backoffice-vincolirichieste',
          component: () => import(/* webpackChunkName: "backoffice-vincolirichieste" */ '@/modules/VincoliRichieste/backoffice/views/index.vue'),
          meta: {
            claims: []
          }
        },
        {
          path: '/back-office/pagamentifissi',
          name: 'backoffice-pagamentifissi',
          component: () => import(/* webpackChunkName: "backoffice-pagamentifissi" */ '@/modules/PagamentiFissi/backoffice/views/index.vue'),
          meta: {
            claims: []
          }
        },
        {
          path: '/back-office/fees',
          name: 'backoffice-fees',
          component: () => import(/* webpackChunkName: "backoffice-fees" */ '@/modules/Canoni/backoffice/views/index.vue'),
          meta: {
            claims: []
          }
        },
        {
          path: '/back-office/configs',
          name: 'backoffice-configs',
          component: () => import(/* webpackChunkName: "backoffice-configs" */ '@/modules/Configs/backoffice/views/Configs.vue'),
          meta: {
            claims: []
          }
        },
        {
          path: '/back-office/users',
          name: 'backoffice-users',
          component: () => import(/* webpackChunkName: "users" */ '@/modules/User/backOffice/views/UsersView/UsersView.vue'),
          props: {
            nogroups: false,
            type: null
          },
          meta: {
            claims: ['USERS.enabled']
          }
        },
        {
          path: '/back-office/roles',
          name: 'backoffice-roles',
          component: () => import(/* webpackChunkName: "users" */ '@/modules/User/backOffice/views/RolesView/RolesView.vue'),
          props: {
            nogroups: false,
            type: null
          },
          meta: {
            claims: ['GROUPS.enabled']
          }
        }
      ],
      meta: {
        claims: ['USERS.enabled', 'GROUPS.enabled', 'APP.canseebackoffice']
      }
    }
  ];
}

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes()
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
