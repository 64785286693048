import { Vue, Component, Prop } from 'vue-property-decorator';
import { FormValidate, Modal } from 'bootstrap-italia'

import { usersService } from '@/modules/User/services/usersService';
import { formatTime } from '@/filters';

@Component({})
export default class CompletaProfilo extends Vue {
    @Prop({ default: `modal_manual_${Math.floor(Math.random() * Date.now())}` })
    id: string;

    user: user.item = null;
    manage: user.item = null;
    modal: any = null;
    defaultDate = '0001-01-01'
    
    get today(): string {
        var t = new Date();
        var dd = t.getDate();
        var mm = t.getUTCMonth() + 1; //January is 0!
        var yyyy = t.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    async mounted() {
        this.user = await usersService.getUser(this.$me().id);
        this.manage = Object.assign({}, this.user);
        this.manage.dateOfBirth = formatTime(this.manage.dateOfBirth, 'YYYY-MM-DD')
        this.manage.dateOfBirth = this.manage.dateOfBirth != this.defaultDate ? this.manage.dateOfBirth : this.today

        const modalEL = this.$refs[this.id];
        this.modal = new Modal(modalEL, {
            keyboard: false,
            backdrop: 'static',
            focus: true
        });
        this.modal.show();

        this.$nextTick(() => {
            this.initValidate();
            this.validate.revalidate();
        });
    }

    beforeDestroy() {
        this.modal.hide();
    }

    validate: any = null;
    errors: boolean = false;
    confirm() {
        this.initValidate();
        this.validate.revalidate().then((valid) => {
            if (valid) {
                usersService.updateUser(this.manage).then((u) => {
                    this.$alert(`Completamento profilo utente`, `Il profilo utente è stato completato con successo!`, {
                        onClose: () => { this.$emit("confirm", u) },
                        buttonCloseTxt: "Continua!",
                        type: 'success'
                    })
                }).catch(() => {
                    this.$alert(`Completamento profilo utente`, `Si è verificato un errore nel completamento del tuo profilo utente.<br> Verifica che i dati inseriti siano corretti e riprova!`, {
                        onClose: () => { },
                        buttonCloseTxt: "Ok",
                        type: 'danger'
                    })
                })
            }

        });
    }

    initValidate() {
        if (this.validate) {
            this.validate.destroy();
            this.validate = null;
        }

        const formId: string = 'validateForm';
        const el = document.getElementById(formId);
        if (!el) {
            console.error(`Form ${formId} not found!`)
            return;
        }

        if (this.validate) {
            this.validate.refresh();
            this.validate.destroy();
            this.validate = false;
        }

        this.validate = new FormValidate(`#${formId}`, {
            errorFieldCssClass: 'is-invalid',
            errorLabelCssClass: 'form-feedback',
            errorLabelStyle: '',
            focusInvalidField: false,
        }).onSuccess((ev) => {
            if (ev?.preventDefault) ev.preventDefault();
            this.errors = false;
        }).onFail((fields) => {
            this.errors = true;
        });

        this.validate
            .addField('#firstName', [{ rule: 'required', errorMessage: 'Questo campo è richiesto' }])
            .addField('#lastName', [{ rule: 'required', errorMessage: 'Questo campo è richiesto' }])
            .addField('#fiscalCode', [{ rule: 'required', errorMessage: 'Questo campo è richiesto' }])
            .addField('#email', [{ rule: 'required', errorMessage: 'Questo campo è richiesto' }])
    }
}