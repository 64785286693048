import CompletaProfilo from "@/modules/User/frontOffice/components/completa/completa.vue";
import { Component, Vue } from "vue-property-decorator";
import { authService } from "./services/authService";
@Component({
   components: {
      CompletaProfilo
   }
})
export default class App extends Vue {
   viewCompletaProfilo: boolean = false;
   beforeUpdate() {
      if (!this.user) return;
      this.viewCompletaProfilo = !this.user.email || !this.user.fiscalCode || !this.user.firstName || !this.user.lastName;
   }

   get user() {
      return this.$me();
   }

   async confirm() {
      await authService.WhoAmI();
      this.viewCompletaProfilo = !this.user.email || !this.user.fiscalCode || !this.user.firstName || !this.user.lastName;
   }
}