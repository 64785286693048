export default {
    dblclickToDelete: "Doppio click per cancellare",
    claims: {
        name: "Nome",
        description: "Descrizione",
        CHAT: {
            cancreate: "Puoi creare una chat",
            canedit: {
                title: "Puoi modificare il titolo",
                users: "Puoi modificare gli utenti di una chat"
            },
            enabled: "Abilita modulo",
        },
        DASHBOARD: {
            cancreate: "Puoi creare una dashboard",
            candelete: "Puoi cancellare una dashboard",
            canedit: "Puoi modificare una dashboard",
            canseeall: "Puoi vedere tutte le dashboard",
            enabled: "Abilita modulo",
        },
        DATASOURCE: {
            cancreate: "Puoi creare una sorgente dati esterna",
            canedit: "Puoi modificare una sorgente dati esterna",
            enabled: "Abilita modulo",
        },
        DYNAMICRULES: {
            enabled: "Abilita modulo",
        },
        GROUPS: {
            canaddusers: "Puoi aggiungere utenti ai gruppi",
            cancreate: "Puoi creare gruppi",
            candelete: "Puoi cancellare un gruppo",
            canedit: "Puoi modificare un gruppo",
            canremoveusers: "Puoi rimuovere un utente dal gruppo",
            canseeothergroups: "Puoi vedere tutti i gruppi",
            enabled: "Abilita modulo",
        },
        ISSUES: {
            canadd: {
                groups: "Puoi aggiungere un gruppo di Issue",
            },
            canarchive: "Puoi archiviare issue",
            canchange: {
                assegnee: "Puoi assegnare una issue",
                group: "Puoi cambiare gruppo ad una issue",
                priority: "Puoi cambiare priorità ad una issue",
                status: "Puoi cambiare stato di un issue",
            },
            canconvert: "Puoi convertire una issue in task",
            cancreate: "Puoi creare issue",
            candelete: "Puoi cancellare issue",
            canedit: {
                customfields: "Puoi modificare i campi aggiuntivi di una issue",
                groups: "Puoi modificare i gruppi di issue",
                others: "Puoi modificare issue assegnate ad altri",
                own: "Puoi modificare issue assegnate a te o create da te",
                states: "Puoi modificare gli stati delle issue",
            },
            canextract: { report: "Puoi esportare in CSV le issue", },
            cansee: { all: "Puoi vedere tutte le issue", },
            enabled: "Abilita modulo",
        },
        TEAMS: {
            canaddusers: "Puoi aggiungere utenti ad un team",
            cancreate: "Puoi creare un team",
            candelete: "Puoi cancellare un team",
            canedit: "Puoi modificare un team",
            canremoveusers: "Puoi rimuovere utenti da un team",
            enabled: "Abilita modulo",
        },
        USERS: {
            canchangepassword: "Puoi modificare la password",
            cancreate: "Puoi creare utenti",
            candelete: "Puoi cancellare utenti",
            canedit: "Puoi modificare utenti",
            enabled: "Abilita modulo",
        },
        WORKSPACE: {
            cancreate: "Puoi creare workspace",
            cancreateExpiring: "Puoi agggiungere una scadenza",
            candelete: "Puoi cancellare una workspace",
            canedit: "Puoi modificare una workspace",
            caneditExpiring: "Puoi modificare la scadenza",
            canseeExpiring: "Puoi vedere le workspace scadute",
            enabled: "Abilita modulo",
        }
    },
    dynamicrule: {
        none1: "Nessuna regola selezionata",
        none2: "Seleziona una regola nella barra di sinistra per vedere i dettagli",
    },
    login: {
        adduser: "Aggiungi un utente",
        contactAdmin: "Contatta l'amministratore per richiedere accesso",
        login_button: "Sign In",
        login_title: "Sign In",
        noAvailableWorkspaces: "Utente non associato a Workspaces",
        password_forgot: "Hai dimenticato la password ?",
        password_input: "Inserisci la password",
        password_label: "Password",
        reset: "Torna alla Login",
        rolesSelector: {
            oneSelectedRole: "Ruolo Selezionato",
            searchRole: "Cerca Ruolo",
            selectedRoles: "Ruoli Selezionati",
            tooltipClosed: "Visibilità ruoli - ${value.length} ruoli selezionati",
            tooltipOpen: "Visibile a tutti i ruoli",
        },
        selectOrganization: "Scegli lo spazio di lavoro",
        username_input: "Inserisci il tuo nome utente",
        username_label: "User",
        error: "Lo username o la password sono errati"
    },
    password: { changed: "Password modificata con successo" },
    roles: {
        managment: "Gestione Permessi"
    },
    role: {
        createNewRoleName: "Nome del ruolo",
        createNewRoleWarning: "Attenzione! Non puoi cambiare nome al ruolo una volta creato.",
    },
    ruleactions: {
        addOneOrMoreTag: "Aggiungi uno o più tag al task/issue",
        addTag: "Aggiungi i tag selezionati al task/issue",
        tagSelector: {
            selectedTag: "Tag Selezionato",
            selectedTags: "Tag Selezionati",
            searchTag: "Cerca Tag",
        },
        assignto: "Assegna il task/issue ad un utente",
        assignToTriggeringUser: "Assegna il task/issue all'utente che ha scatenato l'evento",
        assignToUsernamedTag: "Assegna il task/issue all'utente corrispondente al tag",
        changepriority: "Cambia la priorità",
        createUserNamedTag: "Crea un tag con lo stesso nome dell'utente creato",
        changeIssueGroup: "Cambia il gruppo della issue",
        changeIssueState: "Cambia lo stato della issue",
        changestateto: "Cambia lo stato del task",
        createTaskWithRelatedIssue: {
            label: "Crea un task collegato alla issue",
            groupsLabel: "Gruppo",
            noGroup: "Nessun Gruppo",
            stateLabel: "Stato",
            priorityLabel: "Priorità",
            cloneTags: "Copia i tags",
            cloneLocation: "Copia la posizione geografica",
        },
        copytagstorelated: "Copia i tags dell'oggetto nel task collegato",
        changerelatedissuepriority: "Cambia la priorità della issue associata",
        changerelatedissuestateto: "Cambia lo stato della issue associata",
        changeTaskGroup: {
            label: "Cambia il gruppo del task",
            noGroup: "Nessun Gruppo",
        },
        changeTaskState: "Cambia lo stato del task",
        addRoleToUserToggleRole: "Aggiungi ruolo/i all'utente selezionato"
    },
    securitymanagement: {
        abortButton: "Annulla",
        account: "Account",
        cancelButton: "Annulla",
        resetPassword: {
            label: "Resetta Password",
            modal: {
                title: "Resetta la Password"
            }
        },
        changePassword: {
            label: "Cambia Password",
            modal: {
                title: "Cambia la Password"
            }
        },
        confirmButton: "Conferma",
        edit: "Modifica",
        email: {
            label: "Email *",
            example: "Es: miamail@miodom.it"
        },
        firstName: {
            label: "Nome *",
            example: "Es: Mario"
        },
        lastName: {
            label: "Cognome *",
            example: "Es: Rossi"
        },
        isPasswordLongValid: "6 Caratteri",
        isPasswordDigitValid: "Almeno un numero",
        isPasswordSpecialValid: "Almeno un carattere speciale (es:@#!)",
        isPasswordUpperValid: "Almeno un carattere maiuscolo",
        isPasswordLowerValid: "Almeno un carattere minuscolo",
        isPasswordEquals: "Password e conferma password uguali",
        newrole: "Creazione di un nuovo ruolo",
        newteam: "Crea un nuovo Team",
        newuser: "Creazione di un nuovo utente",
        newWorkspace: "Creazione Nuovo Workspace",
        newWorkspaceModal: { needsExpiring: "Workspace con scadenza", },
        newWorkspaceName: "Nome Workspace",
        oldpassword: "Vecchia password *",
        password: "Password *",
        passwordconfirm: "Conferma password *",
        passwordConfirm: "Conferma password *",
        phoneNumber: "Numero di Telefono",
        roleCreatingTip: "Crea un Nuovo Ruolo",
        roleExistMessage: "Esiste un ruolo con lo stesso nome. ",
        search: "Cerca",
        selectedWorkspace: {
            relatedUsers: "Utenti associati al Workspace selezionato",
            tableTitle: "Nome Utente",
        },
        teamCreatingTip: "Crea un nuovo Team",
        userCreatingTip: "Crea un Nuovo Utente",
        username: {
            label: "Nome Utente *",
            example: "Es: NomeUtente"
        },
        workspaceCreatingTip: "Crea un Nuovo Workspace",
    },
    securitymanager: {
        addusertorole: "Aggiungi utente",
        addusertoteam: "Aggiugni un utente al Team",
        dblclickDeleterule: "Doppio click per cancellare il ruolo",
        dblclickDeleteteam: "Doppio click per cancellare il team",
        deleterule: "Cancella il ruolo",
        deleteuser: "Cancella l'utente",
        deleteteam: "Cancella un Team",
        manageclaims: "Assegna/rimuovi permessi al ruolo",
        manageusers: "Gestione utenti del ruolo",
        manageusersteam: "Gestione dei teams",
        role: {
            label: "Ruolo",
            none1: "Nessun ruolo selezionato",
            none2: "Seleziona un ruolo nella barra di sinistra per vedere i dettagli",
        },
        show: {
            claimsLabel: "Permessi",
            claims: "Visualizza permessi associati al ruolo",
            usersLabel: "Utenti",
            users: "Visualizza gli utenti associati al ruolo",
        },
        team: {
            label: "Team",
            none1: "Nessun team selezionato",
            none2: "Seleziona un team nella barra di sinistra per vedere i dettagli",
        },
        teamCreatingTip: "Crea un nuovo Team",
        user: {
            label: "Account",
            none1: "Nessun utente selezionato",
            none2: "Seleziona un utente nella barra di sinistra per vedere i dettagli",
        },
        usersAddedtorole: "Utenti aggiungi al ruolo",
        usersAddedtoteam: "Utente aggiunto al team",
        usersAddedtoworkspace: "Utenti aggiungi al workspace",
        usersRemovedfromrole: "Utente rimosso dal ruolo",
        usersRemovedfromworkspace: "Utente rimosso dal workspace",
        workspace: {
            expirationDate: "Scadenza workspace",
            label: "Nome workspace",
            none1: "Nessun workspace selezionato",
            none2: "Seleziona un workspace nella barra di sinistra per vedere i dettagli",
        }
    },
    team: {
        createNewTeamName: "Nome del team",
        createNewTeamWarning: "Assegna un nome al nuovo team",
    },
    users: {
        managment: "Gestione utenti"
    },
    user: {
        addRelatedRole: "Aggiungi Ruoli",
        addUserRolesSearch: "Cerca ruolo per nome",
        email: "Email",
        name: "Nome utente",
        noRelatedRoles: "L'utente non ha ruoli assegnati",
        relatedRoleName: "Nome Ruolo",
        relatedRoles: "Ruoli Assegnati all'utente",
        saved: "Utente aggiornato con successo",
    }
}