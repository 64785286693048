import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { Route } from 'vue-router';
import { store } from '@/store';
import { authService } from '@/services/authService';

import router, { constantRoutes, permissionRoutes } from '@/router';
import { dominiService } from '@/services/domini/dominiService';

NProgress.configure({ showSpinner: false });

const whitelistName = ['login', 'loginCredenziali', 'home', '401', '404', 'confermaEmail', 'resetPassword'];

router.beforeEach(async (to: Route, _: Route, next: any) => {
    // Start progress bar
    NProgress.start();
    // Determine whether the user has logged in
    let loadedPermissionRouteClaims = store.getters.permission.loadedPermissionRouteClaims();

    const isAuthenticated = await authService.GetAccessToken();
    if (!!isAuthenticated) {
        // load user info
        try {
            await authService.WhoAmI();
            await authService.GetClaims();
            if (store.getters.domini.comuni().length <= 0) {
                await fetchDomini();
            }
        } catch (err) {
            await authService.Logout();
            loadedPermissionRouteClaims = false;
            store.actions.permission.setLoadedPermissionRouteClaims(loadedPermissionRouteClaims);
            next({ path: `/login?redirect=${to.path}` });

            NProgress.done();
        }

        const redirect = getCookie("redirect");
        if (!!redirect) {
            next({ path: redirect });
            NProgress.done();
            deleteCookie("redirect", null);
        } else {
            if (to.path === '/login') {
                // If is logged in, redirect to the home page
                next({ path: '/', query: to.query });
                NProgress.done();
            } else {
                // Check whether the user has obtained his permission roles
                let claims = store.state.auth.claims || [];
                if (claims.length <= 0 && loadedPermissionRouteClaims) {
                    // Remove token and redirect to login page
                    await authService.Logout();
                    console.warn(`L'utente non ha i permessi necessari ad accedere!`);
                    next({ path: '/login' });
                    loadedPermissionRouteClaims = false;
                    store.actions.permission.setLoadedPermissionRouteClaims(loadedPermissionRouteClaims);

                    NProgress.done();
                } else {
                    if (!loadedPermissionRouteClaims) {

                        claims = await authService.GetClaims();
                        if (!!claims) {
                            loadedPermissionRouteClaims = true;
                            store.actions.permission.setLoadedPermissionRouteClaims(loadedPermissionRouteClaims);

                            // Generate accessible routes map based on claims
                            store.actions.permission.generateRoutes(claims);
                            // Dynamically add accessible routes
                            store.state.permission.dynamicRoutes.forEach(route => {
                                if (!router.getRoutes().find(x => x.name == route.name)) router.addRoute(route);
                            });

                            // Hack: ensure addRoutes is complete
                            // Set the replace: true, so the navigation will not leave a history record
                            next({ ...to, replace: true });
                        }
                        else {
                            await authService.Logout();
                            console.warn(`L'utente non ha i permessi necessari ad accedere!`);
                            next({ path: '/login' });
                            loadedPermissionRouteClaims = false;
                            store.actions.permission.setLoadedPermissionRouteClaims(loadedPermissionRouteClaims);

                            NProgress.done();
                        }
                    } else {
                        if (router.getMatchedComponents(to).length > 0) {
                            next();
                        } else {
                            const allRoutes = permissionRoutes().concat(constantRoutes()).map(value =>
                                [value.path, ...value.children.map(child => child.path)]
                            ).flat();

                            if (allRoutes.includes(to.path))
                                next({ path: "/401", replace: true });
                            else
                                next({ path: "/404", replace: true });
                        }
                    }
                }
            }
        }
    } else {
        // Has no token
        if (to?.name && whitelistName.some(s => to.name === s)) {
            // if ([{name: 'home'}].map(m => m.name).some(s => to.name === s)) {
            // In the free login whitelist, go directly
            next();
        } else {
            // // Other pages that do not have permission to access are redirected to the login page.
            next({ path: `/login?redirect=${to.path}` });
            // next({ path: '/login' });
            loadedPermissionRouteClaims = false;
            store.actions.permission.setLoadedPermissionRouteClaims(loadedPermissionRouteClaims);

            NProgress.done();
        }
    }
});

router.afterEach((to: Route) => {
    NProgress.done();
    document.title = getPageTitle(to.meta.title);
});

const getPageTitle = (title: string) => {
    const baseTitle = `Presentazione procedimenti`
    if (title)
        return `${title} - ${baseTitle}`;
    return `${baseTitle}`;
};

const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}

const deleteCookie = (name, path) => {
    setCookie(name, '', -1, path)
}

const setCookie = (name, value, days = 7, path = '/') => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

const fetchDomini = async () => {
    store.actions.domini.SetComuni(await dominiService.GetComuni());
};

