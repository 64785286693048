import { prelievi } from "@/modules/Prelievi/@types/prelievi";
import { ActionTree, GetterTree } from "vuex";
import { store } from "@/store";
import {StatiRichiesta } from '@/enums/enums'

export interface ProceedingsStoreModel {
  searchTerms: prelievi.query,
}

export interface ProceedingsStoreGetters {
  getSearchTerms: () => prelievi.query
}

export interface ProceedingsStoreActions {
  setSearchTerms: (searchTerms: prelievi.query) => void;
}

export const ProceedingsStore = {
  PREFIX: "proceedings",
  namespaced: true,
  state: {
    searchTerms: {
      skip: 0,
      take: 10,
      assegnatoA : true,
      stato: StatiRichiesta.slice(StatiRichiesta.indexOf('bozza')+1,StatiRichiesta.length)
    }
  },
  getters: {
    getSearchTerms: (state: ProceedingsStoreModel) => (): prelievi.query => state.searchTerms,
  } as GetterTree<ProceedingsStoreModel, ProceedingsStoreModel>,
  mutations: {
    SET_SEARCH_TERMS(state: ProceedingsStoreModel, searchTerms: prelievi.query) {
      state.searchTerms = searchTerms
    },
  },
  actions: {
    setSearchTerms({ commit }, searchTerms) {
      commit("SET_SEARCH_TERMS", searchTerms)
    },
  } as ActionTree<ProceedingsStoreActions, any>
}
