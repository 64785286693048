import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";
import { domini } from "./@types/domini";
import { puntoDiDerivazione } from "@/components/PuntiDiDerivazione/@types/puntoDiDerivazione";

class DominiService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => `${settings.baseUrl}/domini`;
  }

  public async GetComuni(): Promise<domini.comuni[]> {
    return this.Get<domini.comuni[]>(`/searchComuni`);
  }
}


export const dominiService = new DominiService();