import Vue from 'vue';
import Vuex from 'vuex';
import { CreateTypedStoreProxy } from "vuex-typed-store";
import { AppStore, AppStoreActions, AppStoreGetters, AppStoreModel } from './app';
import { AuthStore, AuthStoreActions, AuthStoreGetters, AuthStoreModel } from './auth.store';
import { permissionStore as PermissionStore, PermissionStoreActions, PermissionStoreGetters, PermissionStoreModel } from './permission.store';
import { DominiStore, DominiStoreActions, DominiStoreGetters, DominiStoreModel } from './domini.store';
import { ProceedingsStore, ProceedingsStoreActions, ProceedingsStoreGetters, ProceedingsStoreModel } from './proceedings.store';

Vue.use(Vuex)

export interface IRootState {
  app: AppStoreModel
  auth: AuthStoreModel
  permission: PermissionStoreModel,
  domini: DominiStoreModel,
  proceedings: ProceedingsStoreModel
}

export interface IRootActions {
  app: AppStoreActions
  auth: AuthStoreActions
  permission: PermissionStoreActions,
  domini: DominiStoreActions,
  proceedings: ProceedingsStoreActions
}

export interface IRootGetters {
  app: AppStoreGetters
  auth: AuthStoreGetters
  permission: PermissionStoreGetters,
  domini: DominiStoreGetters,
  proceedings: ProceedingsStoreGetters
}

// Declare empty store first, dynamically register all modules later.
export const vueStore = new Vuex.Store({
  modules: {
    [AppStore.PREFIX]: AppStore,
    [AuthStore.PREFIX]: AuthStore,
    [PermissionStore.PREFIX]: PermissionStore,
    [DominiStore.PREFIX]: DominiStore,
    [ProceedingsStore.PREFIX]: ProceedingsStore
  }
})

export const store = CreateTypedStoreProxy<
  IRootState,
  IRootActions,
  IRootGetters
>(vueStore);